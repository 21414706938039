import React from 'react';
import { useDispatch } from 'react-redux';
import { login } from '../redux/actions/authActions';

const LoginPage = () => {
    const dispatch = useDispatch();

    const handleLogin = (provider) => {
        dispatch(login(provider));
    };

    return (
        <div>
            <h1>Login Page</h1>
            <button onClick={() => handleLogin('yandex')}>Login with Yandex</button>
            <button onClick={() => handleLogin('vk')}>Login with VK</button>
        </div>
    );
};

export default LoginPage;

