import axios from 'axios';

export const addLike = (type, id) => async (dispatch) => {
    try {
        const response = await axios.post('/likes/add', {
            [`${type}Id`]: id
        });
        dispatch({
            type: 'ADD_LIKE_SUCCESS',
            payload: response.data
        });
    } catch (err) {
        dispatch({
            type: 'ADD_LIKE_FAIL',
            payload: err.response.data
        });
    }
};

export const removeLike = (type, id) => async (dispatch) => {
    try {
        const response = await axios.post('/likes/remove', {
            [`${type}Id`]: id
        });
        dispatch({
            type: 'REMOVE_LIKE_SUCCESS',
            payload: response.data
        });
    } catch (err) {
        dispatch({
            type: 'REMOVE_LIKE_FAIL',
            payload: err.response.data
        });
    }
};
