import React, { useEffect, useState } from 'react';
import axios from 'axios';

const AdminPage = () => {
    const [users, setUsers] = useState([]);
    const [videos, setVideos] = useState([]);
    const [articles, setArticles] = useState([]);
    const [comments, setComments] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const usersResponse = await axios.get('/admin/users');
            setUsers(usersResponse.data);
            const videosResponse = await axios.get('/admin/videos');
            setVideos(videosResponse.data);
            const articlesResponse = await axios.get('/admin/articles');
            setArticles(articlesResponse.data);
            const commentsResponse = await axios.get('/admin/comments');
            setComments(commentsResponse.data);
        };

        fetchData();
    }, []);

    const handleDelete = async (type, id) => {
        try {
            await axios.delete(`/admin/${type}/${id}`);
            alert(`${type} deleted`);
        } catch (err) {
            alert(`Error deleting ${type}`);
        }
    };

    return (
        <div>
            <h1>Admin Panel</h1>
            <h2>Users</h2>
            <ul>
                {users.map(user => (
                    <li key={user.id}>
                        {user.name}
                        <button onClick={() => handleDelete('user', user.id)}>Delete</button>
                    </li>
                ))}
            </ul>
            <h2>Videos</h2>
            <ul>
                {videos.map(video => (
                    <li key={video.id}>
                        {video.title}
                        <button onClick={() => handleDelete('video', video.id)}>Delete</button>
                    </li>
                ))}
            </ul>
            <h2>Articles</h2>
            <ul>
                {articles.map(article => (
                    <li key={article.id}>
                        {article.title}
                        <button onClick={() => handleDelete('article', article.id)}>Delete</button>
                    </li>
                ))}
            </ul>
            <h2>Comments</h2>
            <ul>
                {comments.map(comment => (
                    <li key={comment.id}>
                        {comment.text}
                        <button onClick={() => handleDelete('comment', comment.id)}>Delete</button>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default AdminPage;
