import React from 'react';
import VideoList from '../components/VideoList';

const HomePage = () => {
    return (
        <div>
            <h1>Home Page</h1>
            <VideoList />
        </div>
    );
};

export default HomePage;

