import axios from 'axios';

export const login = (provider) => async (dispatch) => {
    try {
        const response = await axios.get(`/auth/${provider}`);
        dispatch({
            type: 'LOGIN_SUCCESS',
            payload: response.data
        });
    } catch (err) {
        dispatch({
            type: 'LOGIN_FAIL',
            payload: err.response.data
        });
    }
};

export const logout = () => async (dispatch) => {
    try {
        await axios.post('/auth/logout');
        dispatch({
            type: 'LOGOUT_SUCCESS'
        });
    } catch (err) {
        dispatch({
            type: 'LOGOUT_FAIL',
            payload: err.response.data
        });
    }
};
