import React from 'react';
import { useDispatch } from 'react-redux';
import { addLike, removeLike } from '../redux/actions/likeActions';

const LikeButton = ({ type, id, isLiked }) => {
    const dispatch = useDispatch();

    const handleLike = () => {
        if (isLiked) {
            dispatch(removeLike(type, id));
        } else {
            dispatch(addLike(type, id));
        }
    };

    return (
        <button onClick={handleLike}>
            {isLiked ? 'Unlike' : 'Like'}
        </button>
    );
};

export default LikeButton;
