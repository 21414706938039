import React, { useEffect, useState } from 'react';
import axios from 'axios';
import LikeButton from './LikeButton';

const VideoList = () => {
    const [videos, setVideos] = useState([]);

    useEffect(() => {
        const fetchVideos = async () => {
            const response = await axios.get('/videos');
            setVideos(response.data);
        };

        fetchVideos();
    }, []);

    return (
        <div>
            <h2>Videos</h2>
            <ul>
                {videos.map(video => (
                    <li key={video.id}>
                        <h3>{video.title}</h3>
                        <p>{video.description}</p>
                        <LikeButton type="video" id={video.id} isLiked={false} />
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default VideoList;

